//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import TripListHistoryTable from '@/modules/trip/components/trip-list-history-table.vue';

// import { i18n } from '@/i18n';

export default {
  name: 'app-trip-list-history-page',

  components: {
    [TripListHistoryTable.name]: TripListHistoryTable,
  },

  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    goToCreatePage(){
      this.$router.push('/trip/new')
    },
    
  },
};
